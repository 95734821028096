import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getUsersProfile () {
    return apiClient.get('api/auth-request/list-compte-administrateur')
  },
  createUser (profileInformations) {
    const formData = new FormData()
    formData.append('nom', profileInformations.nom)
    formData.append('prenom', profileInformations.prenom)
    formData.append('type_compte', profileInformations.type_compte)
    formData.append('password', profileInformations.password)
    formData.append('email', profileInformations.email)
    return apiClient.post('api/auth-request/register-administrateur', formData)
  },

  updateUser (profileInformations) {
    const formData = new FormData()
    formData.append('nom', profileInformations.nom)
    formData.append('prenom', profileInformations.prenom)
    formData.append('type_compte', profileInformations.type_compte)
    formData.append('email', profileInformations.email)
     return apiClient.post(`api/auth-request/update-register-administrateur/${profileInformations.id}`, formData)
  },

  deleteUser (profileInformations) {
    return apiClient.post(`api/profil/delete-ong/${profileInformations.id}`)
 },
}
